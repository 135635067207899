import * as React from 'react'
import BaseLayout from '../components/base-layout'
import SearchBox from '../components/search-box'
import { Col, ListGroup, Row } from 'react-bootstrap'

const IndexPage = () => {
    return (
        <BaseLayout pageTitle="Find the perfect tool to speed up your work | App" pageDescription="Sign in to access a range of simple and secure tools to speed up and automate your everyday work, including merging and splitting spreadsheets, or hashing multiple files.">
            <Row style={{height:"100%"}}>
                <Col style={{textAlign:"center", margin:"32px"}}>
                    <h1 className="display-1" style={{fontWeight:"400"}}><b>Welcome to TNNL</b></h1>
                    <p className="lead"><b>Use the searchbar below to find a tool for your task, or check all the available tools at the bottom of the page.</b></p>
                </Col>
            </Row>
            <SearchBox/>
            <Row style={{textAlign:"center", marginTop:"32px", marginBottom:"0px"}}>
                <Col style={{margin:"auto"}}>
                    <h2 className="display-2"><b>Available tools</b></h2>
                </Col>
            </Row>

            <Row xs={1} md={2} style={{marginTop:"32px", marginBottom:"32px"}}>
                <Col style={{margin:"auto"}}>
                <ListGroup variant="flush">
                    <ListGroup.Item className="lead" style={{background:"#f5efed"}}>
                        <a style={{color:"#957fef", textDecoration:"none"}} href="/tools/merge-spreadsheets"><b>Merging multiple tables</b></a>
                    </ListGroup.Item>
                    <ListGroup.Item className="lead" style={{background:"#f5efed"}}>
                        <a style={{color:"#957fef", textDecoration:"none"}} href="/tools/split-spreadsheets"><b>Splitting tables</b></a>
                    </ListGroup.Item>
                </ListGroup>
                </Col>
                <Col style={{margin:"auto"}}>
                <ListGroup variant="flush">
                    <ListGroup.Item className="lead" style={{background:"#f5efed"}}>
                        <a style={{color:"#957fef", textDecoration:"none"}} href="/tools/hash-files"><b>Bulk file hashing</b></a>
                    </ListGroup.Item>
                    <ListGroup.Item className="lead" style={{background:"#f5efed"}}>
                        <a style={{color:"#957fef", textDecoration:"none"}} href="/tools/append-spreadsheets"><b>Appending multiple tables</b></a>
                    </ListGroup.Item>
                    <ListGroup.Item className="lead" style={{background:"#f5efed"}}>
                        <a style={{color:"#957fef", textDecoration:"none"}} href="/tools/shuffle-rows"><b>Shuffle spreadsheet rows</b></a>
                    </ListGroup.Item>
                </ListGroup>
                </Col>
            </Row>
            <Row style={{textAlign:"center", marginTop:"16px", marginBottom:"32px"}}>
                <Col style={{margin:"auto"}}>
                    <p className="lead"><b>Get access to the newest tools and latest updates to TNNL by <a style={{color:"#957fef", textDecoration:"none"}} href="/sign-up">signing up</a>.</b></p>
                </Col>
            </Row>
        </BaseLayout>
    )
}

export default IndexPage